import React, { ReactNode, useRef } from 'react';
import { StoryProvider } from '@storyofams/storyblok-toolkit';
import { LazyMotion } from 'framer-motion';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ThemeProvider } from 'styled-components';

import theme from '~styles/theme';

const loadFeatures = () =>
  import('./motionFeatures').then((res) => res.default);

type ProviderProps = {
  children: ReactNode;
  /** defaults to empty object */
  pageProps?: any;
};

export const Providers = ({ children, pageProps = {} }: ProviderProps) => {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <ThemeProvider theme={theme}>
          <StoryProvider
            token={process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}
            resolveRelations={[]}
          >
            <LazyMotion features={loadFeatures}>{children}</LazyMotion>
          </StoryProvider>
        </ThemeProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};
